import React from 'react';
import Creatable from 'react-select/creatable';
import { useReactSelectStyles } from '.';

export default function ReactCreatableSelect(props) {
  const { customTheme, customClassName, customStyles } = useReactSelectStyles(
    props
  );
  return (
    <Creatable
      {...props}
      theme={customTheme}
      className={customClassName}
      styles={customStyles}
    />
  );
}
