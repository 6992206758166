import { kea } from 'kea';
import { categoriesLogic } from '.';

export default kea({
  path: () => ['oforce', 'templates'],
  actions: () => ({
    getTemplateGroups: () => ({}),
    setTemplateGroups: groups => groups,
    createTemplateGroup: group => group,
    templateGroupCreated: group => group,
    updateTemplateGroup: group => group,
    templateGroupUpdated: group => group,
    syncTemplateGroup: group => group,
    templateGroupSynced: () => {},
    resetSync: () => {},
    setErrors: (type, errors) => ({ type, errors }),
    getTemplateShells: true,
    setTemplateShells: shells => shells,
    changeTemplateCategory: (option, templateGroup) => ({
      option,
      templateGroup
    }),
    changeUnsubscribeGroup: (unsubscribeGroup, templateGroup) => ({
      unsubscribeGroup,
      templateGroup
    })
  }),
  connect: {
    values: [categoriesLogic, ['categoryFilter']]
  },
  reducers: ({ actions }) => ({
    templateShells: [
      { email: '', sms: '' },
      {
        [actions.setTemplateShells]: (_, shells) => shells
      }
    ],
    templateGroups: [
      {},
      {
        [actions.setTemplateGroups]: (_state, templateGroups) =>
          normalizeById(templateGroups),
        [actions.templateGroupCreated]: (groups, newGroup) => ({
          ...groups,
          [newGroup.id]: newGroup
        }),
        [actions.templateGroupUpdated]: (groups, updatedGroup) => ({
          ...groups,
          [updatedGroup.id]: updatedGroup
        })
      }
    ],
    loading: [
      false,
      {
        [actions.getTemplateGroups]: () => true,
        [actions.setTemplateGroups]: () => false,
        [actions.createTemplateGroup]: () => true,
        [actions.templateGroupCreated]: () => false,
        [actions.setErrors]: () => false
      }
    ],
    updating: [
      false,
      {
        [actions.updateTemplateGroup]: () => true,
        [actions.templateGroupUpdated]: () => false
      }
    ],
    synced: [
      false,
      {
        [actions.templateGroupSynced]: () => true,
        [actions.resetSync]: () => false
      }
    ],
    syncing: [
      false,
      {
        [actions.syncTemplateGroup]: () => true,
        [actions.templateGroupSynced]: () => false,
        [actions.setErrors]: () => false,
        [actions.resetSync]: () => false
      }
    ],
    errors: [
      {},
      {
        [actions.setErrors]: (state, { errors, type }) => ({
          ...state,
          [type]: errors
        }),
        [actions.resetSync]: state => ({
          ...state,
          sync: null
        })
      }
    ],
    currentTemplateGroup: [
      {},
      {
        [actions.createTemplateGroup]: () => ({}),
        [actions.templateGroupCreated]: (_state, group) => group
      }
    ]
  }),
  listeners: ({ actions, values }) => ({
    [actions.getTemplateGroups]: async () => {
      const url = `/notifications/templategroups`;
      const opts = { method: 'GET', credentials: 'include' };
      const { data } = await fetch(url, opts).then(x => x.json());
      actions.setTemplateGroups(data);
    },
    [actions.createTemplateGroup]: async group => {
      const url = `/notifications/templategroups`;
      const opts = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(group),
        headers: { 'Content-Type': 'application/json' }
      };
      const { data, errors } = await fetch(url, opts).then(x => x.json());
      data
        ? actions.templateGroupCreated(data)
        : actions.setErrors('create', errors);
    },
    [actions.updateTemplateGroup]: async group => {
      const url = `/notifications/templategroups/${group.id}`;
      const opts = {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(group),
        headers: { 'Content-Type': 'application/json' }
      };
      const { data, errors } = await fetch(url, opts).then(x => x.json());
      data
        ? actions.templateGroupUpdated(data)
        : actions.setErrors('update', errors);
    },
    [actions.changeTemplateCategory]: async ({ option, templateGroup }) => {
      const category = option && { name: option.label, id: option.value };
      await actions.updateTemplateGroup({ ...templateGroup, category });
    },
    [actions.changeUnsubscribeGroup]: async ({
      unsubscribeGroup,
      templateGroup
    }) => {
      await actions.updateTemplateGroup({
        ...templateGroup,
        unsubscribe_group: (unsubscribeGroup && unsubscribeGroup.value) || null
      });
    },
    [actions.getTemplateShells]: async () => {
      const shells = await fetch('/template_shells').then(x => x.json());
      actions.setTemplateShells(shells);
    },
    [actions.syncTemplateGroup]: async group => {
      const url = `/notifications/templategroups/${group.id}/sync`;
      const opts = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      };
      const response = await fetch(url, opts).then(x => x);
      if (response.status === 200) {
        actions.templateGroupSynced();
        setTimeout(() => actions.resetSync(), 3000);
      } else {
        actions.setErrors('sync', ['Sync failed']);
      }
    }
  }),
  selectors: ({ selectors }) => ({
    templateGroupList: [
      () => [selectors.templateGroups],
      groups => denormalize(groups)
    ],
    filteredTemplateGroups: [
      () => [selectors.templateGroupList, selectors.categoryFilter],
      (groups, category) =>
        category && category.id ? filterByCategory(groups, category) : groups
    ]
  })
});

const normalizeById = array =>
  array.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

const denormalize = obj =>
  Object.keys(obj).reduce((acc, key) => [...acc, obj[key]], []);

const filterByCategory = (groups, category) =>
  groups.filter(
    group =>
      group.category && group.category.id && group.category.id === category.id
  );
