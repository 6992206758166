import React from 'react';
import root from 'react-shadow';
import { Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useActions, useValues } from 'kea';
import { testSendLogic } from '../SendTest';
import { templatesLogic } from '../../../../kea-logic';

export default function Preview({ formikProps }) {
  const { dirty, values } = formikProps;
  const { toggleEmailModal } = useActions(testSendLogic);
  const { templateShells } = useValues(templatesLogic);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Label className="font-weight-bold mb-1">Email Preview</Label>
        <Button
          color="link"
          className="text-info p-0"
          disabled={dirty}
          onClick={toggleEmailModal}
        >
          <FontAwesomeIcon icon={['far', 'envelope']} className="mr-2 mb-1" />
          Send Test Email
        </Button>
      </div>
      <div className="d-flex flex-fill border border-gray-400 bg-white">
        <root.div>
          <div
            dangerouslySetInnerHTML={{
              __html: templateShells.email.replace(
                /(<%= @body %>)/g,
                values.message
              )
            }}
          ></div>
        </root.div>
      </div>
    </>
  );
}
