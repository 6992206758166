const defaults = {
  header: true,
};

function parseCsvFromBlob(blob, options = defaults) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = () => {
      const csv = parseCsvFromString(reader.result, options);
      resolve(csv);
    };

    reader.readAsText(blob);
  });
}

function parseCsvFromString(content, options = defaults) {
  let [header, ...lines] = content.trim().split(/\r\n|\r|\n/);
  
  header = header
    .replace(/[\n\r]/g)
    .split(',')
    .map((x) => x.trim());
    
  lines = lines.map((x) => x.trim().split(','));

  if (options.header === true) {
    return lines.map((values) => zip(header, values));
  }

  return lines;
}

const zip = (header, values) =>
  header.reduce((acc, prop, index) => {
    const propValue = values[index];
    if (propValue && propValue !== '') {
      return { ...acc, [prop]: propValue };
    }
    return acc;
  }, {});

export { parseCsvFromBlob, parseCsvFromString };
