import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loader = ({ className = '' }) => (
  <div className={`text-center p-3 text-info w-100 ${className}`}>
    <FontAwesomeIcon icon={['far', 'spinner-third']} spin size="2x" />
  </div>
);

export default Loader;
