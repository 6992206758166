import React, { useState, useEffect } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  SendTestEmail,
  SendTestSms,
  SmsTemplateForm,
  EmailTemplateForm,
} from '../TemplateGroup';
import { Tab } from '../../../components';
import { ChangeCategory, ChangeUnsubscribeGroup } from '.';
import { useValues, useActions } from 'kea';
import { templatesLogic } from '../../../kea-logic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Form({ templateGroup }) {
  const { syncTemplateGroup } = useActions(templatesLogic);
  const { syncing, errors, synced } = useValues(templatesLogic);
  const { name, category, unsubscribe_group } = templateGroup;

  const [activeTab, setActiveTab] = useState(
    JSON.parse(localStorage.getItem('activeTab')) || '1'
  );

  useEffect(() => {
    if (activeTab) localStorage.setItem('activeTab', JSON.stringify(activeTab));
  }, [activeTab]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const syncEnvironmentName =
    process.env.REACT_APP_NODE_ENV === 'production' ? 'Staging' : 'Production';

  return (
    <div className="p-3 bg-white shadow-sm border border-gray-400">
      <div className="d-flex justify-content-start align-items-start mb-3">
        <div className="h5 mb-0 font-weight-bold mr-3">{name}</div>
        <div className="d-inline-block ml-auto form-inline pl-3">
          <FormGroup inline>
            {errors.sync &&
              errors.sync.map((error, i) => (
                <div className="text-right text-danger small mr-3" key={i}>
                  {error}
                </div>
              ))}
            {synced && (
              <div className="text-right text-success small mr-3">
                Sync successful
              </div>
            )}
            <Button
              style={{ width: '200px' }}
              color="info"
              size="sm"
              outline
              disabled={syncing || synced}
              onClick={() => syncTemplateGroup(templateGroup)}
            >
              <FontAwesomeIcon
                icon={['far', 'sync-alt']}
                spin={syncing}
                className="mr-2"
              />
              Sync{syncing && 'ing'} with {syncEnvironmentName}
            </Button>
          </FormGroup>
        </div>
      </div>

      <Row className="mb-3">
        <Col xs="12" md="6">
          <FormGroup>
            <Label className="mb-1 font-weight-bold">Category</Label>

            <ChangeCategory category={category} templateGroup={templateGroup} />
          </FormGroup>
          <FormGroup>
            <Label className="mb-1 font-weight-bold">Unsubscribe Group</Label>
            <ChangeUnsubscribeGroup
              unsubscribeGroup={unsubscribe_group}
              templateGroup={templateGroup}
            />
          </FormGroup>
        </Col>
      </Row>

      <div className="d-flex border-bottom border-gray-400 mb-4">
        <Tab active={activeTab === '1'} onClick={() => toggle('1')}>
          Email
        </Tab>
        <Tab active={activeTab === '2'} onClick={() => toggle('2')}>
          SMS
        </Tab>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <EmailTemplateForm templateGroup={templateGroup} />
        </TabPane>
        <TabPane tabId="2">
          <SmsTemplateForm templateGroup={templateGroup} />
        </TabPane>
      </TabContent>
      <SendTestEmail templateGroup={templateGroup} />
      <SendTestSms templateGroup={templateGroup} />
    </div>
  );
}
