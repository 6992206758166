import React from 'react';
import { Button } from '@oforce/global-components';

export default function CancelButton({ loading, toggle }) {
  return (
    <Button
      color="secondary"
      outline
      size="sm"
      disabled={loading}
      onClick={toggle}
    >
      Cancel
    </Button>
  );
}
