import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledRecipient = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.gray300};
  background-color: ${({ selected, theme }) =>
    selected ? theme.gray300 : 'white'};

  cursor: pointer;

  &:after {
    color: ${({ theme }) => theme.gray900};
    content: ${({ selected }) => (selected ? "' ✔︎'" : "''")};
    float: right;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export default function RecipientsTable({ recipients, onSelected }) {
  const [selected, setSelected] = useState(recipients?.[0]?.value);

  useEffect(() => {
    if (selected) onSelected(selected);
  }, [onSelected, selected]);

  return (
    <>
      {recipients.map((recipient) => {
        const key = recipient.value.toString();
        const isSelected = selected?.toString() === key;
        return (
          <StyledRecipient
            key={key}
            selected={isSelected}
            onClick={() => setSelected(recipient.value)}
            className="px-3 py-2"
          >
            {key}
          </StyledRecipient>
        );
      })}
    </>
  );
}
