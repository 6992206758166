import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ButtonLoading(props) {
  const { isLoading, loadingText, children, className, ...rest } = props;
  const extraPadding = isLoading && loadingText.length <= 0;

  return (
    <Button
      {...rest}
      className={extraPadding ? `px-5 ${className}` : className}
    >
      {isLoading ? (
        <Fragment>
          <FontAwesomeIcon
            icon={['far', 'spinner-third']}
            spin
            className={loadingText && 'mr-2'}
          />
          {loadingText}
        </Fragment>
      ) : (
        children
      )}
    </Button>
  );
}

ButtonLoading.defaultProps = {
  loadingText: ''
};
