import React from 'react';
import { useValues } from 'kea';
import { templatesLogic } from '../../kea-logic';
import { Group, ListHeader, CategoryFilter } from '.';
import { Placeholder, Loader } from '../../components';

export default function List() {
  const { loading, filteredTemplateGroups } = useValues(templatesLogic);

  return (
    <div className="bg-white border-left border-top border-gray-400 shadow-sm">
      <div className="border-bottom border-right border-gray-400 p-3">
        <CategoryFilter />
      </div>
      <ListHeader />

      {filteredTemplateGroups && filteredTemplateGroups.length > 0 ? (
        filteredTemplateGroups.map((group) => (
          <Group key={group.id} group={group} />
        ))
      ) : (
        <div className="border-gray-400 border-right border-bottom">
          {loading ? (
            <Loader />
          ) : (
            <Placeholder className="bg-white shadow-sm p-3 text-center rounded">
              <p className="mb-0">No Template Groups found</p>
            </Placeholder>
          )}
        </div>
      )}
    </div>
  );
}
