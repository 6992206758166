import { kea } from 'kea';

export default kea({
  path: () => ['oforce', 'test-send'],
  actions: () => ({
    toggleEmailModal: () => ({}),
    toggleSmsModal: () => ({}),
    sendTest: (notification) => notification,
    testSent: (recipient) => recipient,
    setErrors: (errors) => errors,
    clearSent: false,
  }),
  reducers: ({ actions }) => ({
    sent: [
      null,
      {
        [actions.sendTest]: (state) => state,
        [actions.testSent]: (_state, recipient) => recipient,
        [actions.clearSent]: (state) => state,
      },
    ],
    errors: [
      [],
      {
        [actions.sendTest]: () => [],
        [actions.setErrors]: (errors) => errors,
      },
    ],
    showEmailModal: [
      false,
      { [actions.toggleEmailModal]: (showEmailModal) => !showEmailModal },
    ],
    showSmsModal: [
      false,
      { [actions.toggleSmsModal]: (showSmsModal) => !showSmsModal },
    ],
  }),
  listeners: ({ actions }) => ({
    [actions.sendTest]: async ({ recipient, canonical_name, type }) => {
      const url = `/notifications`;
      const opts = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          force_recipient: true,
          recipient: {
            [type]: recipient,
          },
          data: {},
          template_group: canonical_name,
        }),
      };
      const { data, errors } = await fetch(url, opts).then((x) => x.json());
      data ? actions.testSent(recipient) : actions.setErrors(errors);
      setTimeout(() => actions.clearSent(), 3000);
    },
  }),
});
