import { useCallback, useState } from 'react';
import {
  EMAIL_RECIPIENTS,
  SMS_RECIPIENTS,
  EmailRecipient,
  SmsRecipient,
  RecipientSet,
} from '../../../utils/recipients';

/**
 * A hook to read and write recipient select options to the session store.
 * @param {string} type - The type of recipients we are interested in.
 */
export default function useRecipientOptionsSession({ type }) {
  const [options, setOptions] = useState(Store.getOptions(type));

  const doSetOptions = useCallback(
    (options) => {
      setOptions(Store.set(type, options));
    },
    [type]
  );

  return {
    setOptions: doSetOptions,
    options,
    recipientsToOptions: Options.fromArray
  };
}

const SESSION_KEY = '_oforce_notifications_recipients';

const Store = {
  getFullSet() {
    return RecipientSet.parse(sessionStorage.getItem(SESSION_KEY));
  },
  get(type, set = null) {
    const workingSet = set || Store.getFullSet();
    switch (type) {
      case EMAIL_RECIPIENTS:
        return Array.from(workingSet.emailRecipients());
      case SMS_RECIPIENTS:
        return Array.from(workingSet.smsRecipients());
      default:
        throw Error(`Unsupported type: '${type}'`);
    }
  },
  getOptions(type) {
    return Options.fromArray(Store.get(type));
  },
  set(type, options) {
    const set = new RecipientSet();
    Options.parseRecipients(type, options).forEach((x) => set.add(x));

    const fullSet = Store.getFullSet();
    const finalSet = fullSet.merge(set);

    sessionStorage.setItem(SESSION_KEY, finalSet.serialize());

    return Options.fromArray(Store.get(type, finalSet));
  },
};

const Options = {
  fromArray(recipients) {
    const options = [];
    recipients.forEach((recipient) =>
      options.push({ label: recipient.toString(), value: recipient })
    );
    return options;
  },

  parseRecipients: (type, options) => options.map(Options.parseRecipient(type)),

  parseRecipient: (type) => (recipientOrOption) => {
    if (recipientOrOption.value) {
      return Options.parseRecipientFromOption(type, recipientOrOption);
    }
    return recipientOrOption;
  },

  parseRecipientFromOption(type, { value }) {
    if (typeof value === 'string') {
      switch (type) {
        case EMAIL_RECIPIENTS:
          return new EmailRecipient(value);
        case SMS_RECIPIENTS:
          return new SmsRecipient(value);
        default:
          throw Error(`Unsupported type: '${type}'`);
      }
    }
    return value;
  },
};
