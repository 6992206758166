import React, { useRef } from 'react';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Alert,
} from 'reactstrap';
import { ButtonLoading } from '../../../../../components';
import { LabelField } from '../../../../../components/formik';
import { Form as FormikForm, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { testSendLogic } from '..';
import { useActions, useValues } from 'kea';

export default function Form({ handleSubmit, errors, touched, isSubmitting }) {
  const recipientInputEl = useRef(null);
  const { toggleEmailModal } = useActions(testSendLogic);

  const {
    showEmailModal,
    sent: recipient,
    errors: sendErrors,
  } = useValues(testSendLogic);

  return (
    <FormikForm>
      <Modal isOpen={showEmailModal} toggle={toggleEmailModal} scrollable>
        <ModalHeader toggle={toggleEmailModal}>Send Test Email</ModalHeader>
        <ModalBody>
          <fieldset disabled={isSubmitting}>
            {recipient && (
              <Alert color="success">
                Your test email has been sent to {recipient.address}.
              </Alert>
            )}
            {sendErrors && sendErrors.length > 0 && (
              <Alert color="danger">
                {sendErrors.map((error) => (
                  <div>{error}</div>
                ))}
              </Alert>
            )}

            <LabelField labelName="Recipient" fieldName="recipient" required />
            <Field
              name="recipient"
              type="email"
              placeholder="Enter the recipient's email address"
              invalid={errors['recipient'] && touched['recipient']}
              as={Input}
              innerRef={recipientInputEl}
            />
            <ErrorMessage name="recipient" component={FormFeedback} />
          </fieldset>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            color="secondary"
            outline
            size="sm"
            onClick={toggleEmailModal}
          >
            Cancel
          </Button>
          <ButtonLoading
            isLoading={isSubmitting}
            loadingText="Sending..."
            color="info"
            size="sm"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon="paper-plane" className="mr-2" />
            Send Test Email
          </ButtonLoading>
        </ModalFooter>
      </Modal>
    </FormikForm>
  );
}
