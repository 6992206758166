import React from 'react';
import { Formik } from 'formik';
import { testSendLogic } from '..';
import { SendTestEmailForm } from '../Email';
import * as yup from 'yup';
import { useActions } from 'kea';

export default function Email({ templateGroup }) {
  const { sendTest } = useActions(testSendLogic);
  const { canonical_name } = templateGroup;

  return (
    <Formik
      initialValues={{ recipient: '' }}
      validationSchema={yup.object().shape({
        recipient: yup
          .string()
          .email()
          .required('Required')
      })}
      onSubmit={async (values, actions) => {
        const { recipient } = values;
        await sendTest({ recipient: {address: recipient}, canonical_name, type: 'email' });
        actions.setSubmitting(false);
      }}
    >
      {formikProps => <SendTestEmailForm {...formikProps} />}
    </Formik>
  );
}
