import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faSignOut,
  faExclamationTriangle,
  faCircle,
  faPaperPlane,
  faInfoCircle as fasInfoCircle,
  faAngleUp,
  faAngleDown,
  faEnvelope,
  faSms,
  faCopy,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faPlus,
  faSpinnerThird,
  faLongArrowAltLeft,
  faInfoCircle,
  faMobile,
  faSyncAlt,
  faSitemap,
  faSubscript,
  faQuestionCircle,
  faFilter,
  faPen,
  faCopy as farCopy,
  faSms as farSms,
  faEnvelope as farEnvelope,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  farCopy,
  farSms,
  farEnvelope,
  faCopy,
  faSms,
  faExclamationTriangle,
  faHome,
  faPlus,
  faSignOut,
  faCircle,
  faSpinnerThird,
  faLongArrowAltLeft,
  faCopy,
  faInfoCircle,
  faEnvelope,
  faPaperPlane,
  faSms,
  faMobile,
  faSyncAlt,
  fasInfoCircle,
  faSitemap,
  faSubscript,
  faQuestionCircle,
  faAngleUp,
  faAngleDown,
  faFilter,
  faPen
);
