import React from 'react';
import { GlobalNavigation } from '@oforce/global-navigation';
import Routes from './Routes';
import useUser from './hooks/useUser';

function App() {
  const { user, logout } = useUser();

  return (
    <div className="d-flex">
      <GlobalNavigation
        appName="Notifications"
        environment={process.env.REACT_APP_NODE_ENV}
        // profileItems={[]}
        userInfo={user}
        isAuthenticated={true}
        menuItems={[
          {
            name: 'Templates',
            pathname: '/templates',
            icon: ['fas', 'copy'],
            useRouter: true,
          },
          {
            name: 'Emails',
            pathname: '/emails',
            icon: ['fas', 'envelope'],
            useRouter: true,
          },
          {
            name: 'SMS Messages',
            pathname: '/sms-messages',
            icon: ['fas', 'sms'],
            useRouter: true,
          },
        ]}
        login={() => {}}
        logout={logout}
      />
      <div className="page-content">
        <div className="p-3 p-md-4">
          <Routes />
        </div>
      </div>
    </div>
  );
}

export default App;
