import { Label } from '@oforce/global-components';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Input, Row, UncontrolledTooltip } from 'reactstrap';

export default function VariableEditor({ variables, formik, onChange }) {
  const [values, setValues] = useState({});

  useEffect(() => {
    formik.setFieldValue('data', values);
    onChange && onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function setValue(name, isList) {
    return ({ target }) => {
      const value = isList ? target.value.split(',').map(x => x.trim()) : target.value;
      return setValues((values) => ({ ...values, [name]: value }));
    };
  }

  if (!variables || variables.length === 0) return null;

  return (
    <>
      <Label>Variables</Label>
      {variables.map((variable, i) => {
        const isListType = variable.startsWith('list:');
        const name = variable.replace('list:', '');
        return (
          <Row key={i}>
            <Col sm={1}>{isListType && <ListVariableHint name={name} />}</Col>
            <Col sm={6}>
              <Input
                type="text"
                disabled
                value={name}
                id={name}
                onChange={() => {}}
              />
            </Col>
            <Col sm={5}>
              <Input type="text" onChange={setValue(name, isListType)} />
            </Col>
          </Row>
        );
      })}
    </>
  );
}

function ListVariableHint({ name }) {
  return (
    <>
      <UncontrolledTooltip target={name}>
        Values should be entered with commas separating them.
      </UncontrolledTooltip>
      <FontAwesomeIcon icon="info-circle" id={name} />
    </>
  );
}
