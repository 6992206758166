import React from 'react';
import { FormGroup, FormFeedback } from 'reactstrap';
import { Field, ErrorMessage } from 'formik';
import { LabelField } from './index';
import { ReactCreatableSelect } from '../ReactSelect';

export default function CreatableSelect(props) {
  const { options, name, label, required, optional, ...rest } = props;
  return (
    <FormGroup className="w-100 mb-4">
      {!!label && (
        <LabelField
          labelName={label}
          fieldName={name}
          required={required}
          optional={optional}
        />
      )}
      <Field name={name}>
        {({ field, form }) => {
          const { name, value } = field;
          const { setFieldValue, setFieldTouched, errors, touched } = form;
          const invalid = errors[name] && touched[name];
          return (
            <ReactCreatableSelect
              options={options}
              invalid={invalid}
              value={value}
              onChange={option => {
                setFieldValue(name, option);
              }}
              onBlur={() => setFieldTouched(name, true)}
              {...rest}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={FormFeedback} className="d-block" />
    </FormGroup>
  );
}
