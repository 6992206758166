import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useCursorPagination } from '../../hooks';
import NotificationRow from './NotificationRow';

export const GET_NOTIFICATIONS = gql`
  query Notifications(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $type: NotificationType
  ) {
    notifications(
      type: $type
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        node {
          id
          ...NotificationRow_Notification
        }
      }
    }
  }
  ${NotificationRow.fragments.NotificationRow_Notification}
`;

const NOTIFICATION_TYPE = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};

export { NOTIFICATION_TYPE };

export default function useNotifications({ numberToDisplay = 25, type }) {
  const [loadNotifications, { data, loading, error }] = useLazyQuery(
    GET_NOTIFICATIONS,
    { fetchPolicy: 'cache-and-network', pollInterval: 10000 }
  );

  useEffect(
    () => loadNotifications({ variables: { first: numberToDisplay, type } }),
    [loadNotifications, numberToDisplay, type]
  );

  const { loadNextPage, loadPreviousPage } = useCursorPagination(
    loadNotifications,
    {
      count: numberToDisplay,
      type,
    }
  );

  const pageInfo = data?.notifications?.pageInfo || {};
  const { hasNextPage, hasPreviousPage } = pageInfo;

  return {
    notifications: data?.notifications?.edges?.map((x) => x.node) || [],
    loading,
    error,
    hasNextPage,
    hasPreviousPage,
    loadNextPage: () => loadNextPage(pageInfo),
    loadPreviousPage: () => loadPreviousPage(pageInfo),
  };
}
