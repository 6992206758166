import React from 'react';
import { FormGroup } from 'reactstrap';
import { ReactSelect } from '../../../components/ReactSelect';
import { categoriesLogic, templatesLogic } from '../../../kea-logic';
import { useValues, useActions } from 'kea';

export default function ChangeCategory({ category, templateGroup }) {
  const { updating } = useValues(templatesLogic);
  const { categoryOptions, loading } = useValues(categoriesLogic);
  const { changeTemplateCategory } = useActions(templatesLogic);
  return (
    <FormGroup className="mb-0">
      <ReactSelect
        options={categoryOptions}
        value={category && { label: category.name, value: category.id }}
        isClearable
        isLoading={loading || updating}
        isDisabled={loading || updating}
        placeholder="Change category..."
        onChange={option => changeTemplateCategory(option, templateGroup)}
        onClear={option => changeTemplateCategory(option, templateGroup)}
      />
    </FormGroup>
  );
}
