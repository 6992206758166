import React from 'react';
import { parseISO, format } from 'date-fns';

const SCHEDULED_AT_REGEX = /scheduled for \[(?<date>.*)\]/;

export default function NotificationStatus({ status, ...rest }) {
  if(!status) return null
  
  let statusText = status;
  const match = status.match(SCHEDULED_AT_REGEX);

  if (match) {
    const isoDate = match.groups['date'];
    const date = format(parseISO(isoDate), 'eee LLL do yyyy hh:mm aaa');
    statusText = `Scheduled for ${date}`;
  }

  return <div {...rest}>{statusText}</div>;
}
