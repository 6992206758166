import React, { useState } from 'react';
import { Label, ReactSelectField } from '@oforce/global-components';
import { useTemplateGroups } from '../hooks';

export default function TemplateSelect({ type, onChange }) {
  const { options, searchName } = useTemplateGroups({ type });
  const [input, setInput] = useState('');

  const updateInput = (input) => {
    searchName(input);
    setInput(input);
    return input;
  };

  return (
    <>
      <Label>Template</Label>
      <ReactSelectField
        options={options}
        defaultInputValue={input}
        name="template"
        placeholder="Search for template..."
        isClearable
        onChange={onChange}
        onInputChange={updateInput}
      />
    </>
  );
}
