import React from 'react';
import { Formik } from 'formik';
import { SendTestSmsForm } from '../Sms';
import * as yup from 'yup';
import { useActions } from 'kea';
import { testSendLogic } from '..';

export default function Sms({ templateGroup }) {
  const { sendTest } = useActions(testSendLogic);
  const { canonical_name } = templateGroup;

  return (
    <Formik
      initialValues={{ recipient: '' }}
      validationSchema={yup.object().shape({
        recipient: yup
          .string()
          .required('Required')
          .min(10)
      })}
      onSubmit={async (values, actions) => {
        const { recipient } = values;
        await sendTest({ recipient, canonical_name, type: 'mobile_phone' });
        actions.setSubmitting(false);
      }}
    >
      {formikProps => <SendTestSmsForm {...formikProps} />}
    </Formik>
  );
}
