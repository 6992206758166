import React from 'react';
import {
  Row,
  Col,
  Input,
  FormGroup,
  FormFeedback,
  Alert,
  Button
} from 'reactstrap';
import { ButtonLoading, TemplateHelp } from '../../../../components';
import { InputField, LabelField } from '../../../../components/formik';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmailPreview } from '../../TemplateGroup';
import { useEmailTemplateForm } from '../EmailTemplate';

export default function Form({ templateGroup }) {
  const {
    initialValues,
    schema,
    handleSubmit,
    updateErrors,
    updating
  } = useEmailTemplateForm(templateGroup);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const {
          isSubmitting,
          errors,
          handleChange,
          handleSubmit,
          touched,
          values,
          dirty,
          setFieldValue
        } = formikProps;
        return (
          <FormikForm onSubmit={handleSubmit}>
            <Row>
              <Col xs="12" md="6" className="mb-5 mb-md-0">
                {updateErrors && updateErrors.length > 0 && (
                  <Alert color="danger">
                    {updateErrors.map(error => (
                      <div>{error}</div>
                    ))}
                  </Alert>
                )}
                <fieldset disabled={isSubmitting || updating}>
                  <Row form>
                    <Col>
                      <InputField
                        errors={errors}
                        name="reply_to"
                        required
                        label="Reply To"
                        onChange={handleChange}
                        placeholder="Enter the reply to email"
                        touched={touched}
                        type="text"
                      />
                    </Col>
                    <Col>
                      <InputField
                        className="mb-1"
                        errors={errors}
                        name="bcc"
                        optional
                        label="BCC"
                        onChange={handleChange}
                        placeholder="Enter a comma seperated list of email addresses"
                        touched={touched}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <InputField
                    errors={errors}
                    name="subject"
                    required
                    label="Subject"
                    onChange={handleChange}
                    placeholder="Enter the email subject"
                    touched={touched}
                    type="text"
                  />

                  <FormGroup className="mb-1">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <LabelField
                        labelName="Message"
                        fieldName="message"
                        required
                        className="d-inline-block mb-0"
                      />
                      <Button
                        tabIndex={6}
                        color="link"
                        className="text-info p-0"
                        onClick={e => {
                          e.preventDefault();
                          setFieldValue(
                            'message',
                            values.message + ' <%= @VARIABLE %>'
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'plus']}
                          className="mr-1 mb-1"
                        />
                        Variable
                      </Button>
                    </div>

                    <Field
                      name="message"
                      as={Input}
                      type="textarea"
                      style={{ minHeight: '200px' }}
                      placeholder="Enter the email message"
                      invalid={errors['message'] && touched['message']}
                    />
                    <ErrorMessage name="message" component={FormFeedback} />
                  </FormGroup>
                </fieldset>
                <TemplateHelp />
                <div className="d-flex align-items-center justify-content-end">
                  <ButtonLoading
                    isLoading={isSubmitting | updating}
                    color="success"
                    type="submit"
                    onClick={handleSubmit}
                    size="sm"
                    disabled={!dirty}
                  >
                    Save Changes
                  </ButtonLoading>
                </div>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex align-items-stretch flex-column"
              >
                <EmailPreview formikProps={formikProps} />
              </Col>
            </Row>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
