import { useLazyQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import gql from 'graphql-tag';
import { useEffect } from 'react';

const GET_CURRENT_USER = gql`
  query currentUser {
    user: currentUser {
      name
      email
      roles
    }
  }
`;

export default function useUser() {
  const { isAuthenticated, logout } = useAuth0();
  const [getUser, { data }] = useLazyQuery(GET_CURRENT_USER);

  useEffect(() => {
    if (isAuthenticated && !data) getUser();
  }, [getUser, isAuthenticated, data]);

  return {
    user: data?.user,
    logout,
  };
}
