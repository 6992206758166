import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Templates, Emails, SmsMessages } from './pages/';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/templates" />
      </Route>
      <Route path="/templates">
        <Templates />
      </Route>
      <Route path="/emails">
        <Emails />
      </Route>
      <Route path="/sms-messages">
        <SmsMessages />
      </Route>
    </Switch>
  );
};

export default withAuthenticationRequired(Routes, {
  onRedirecting: () => <div>...authenticating</div>,
});
