import React from 'react';
import { Button } from '@oforce/global-components';

export default function SendButton({ onSend, loading, disabled }) {
  return (
    <Button
      color="success"
      type="submit"
      size="sm"
      disabled={loading || disabled}
      loading={loading}
      onClick={onSend}
    >
      Send Notification
    </Button>
  );
}
