import React from 'react';
import gql from 'graphql-tag';

import { formatRelative, parseISO } from 'date-fns';

import { Row, Col } from 'reactstrap';
import { NOTIFICATION_TYPE } from './useNotifications';
import NotificationTemplate from './NotificationTemplate';
import NotificationStatus from './NotificationStatus';

NotificationRow.fragments = {
  NotificationRow_Notification: gql`
    fragment NotificationRow_Notification on Notification {
      subject
      recipient
      status
      lastStatusDate
      type
      ...NotificationTemplate_Notification
    }
    ${NotificationTemplate.fragments.NotificationTemplate_Notification}
  `,
};

const cellClasses = 'border-right border-gray-400';

export default function NotificationRow({ notification = {} }) {
  return notification.type === NOTIFICATION_TYPE.EMAIL ? (
    <EmailRow notification={notification} />
  ) : (
    <SmsRow notification={notification} />
  );
}

function EmailRow({ notification }) {
  const { recipient, subject, status, lastStatusDate } = notification;

  return (
    <Row
      className="d-flex align-items-stretch align-content-stretch border-bottom border-gray-400"
      noGutters
    >
      <Col md="2" lg="2" className={cellClasses}>
        <NotificationTemplate notification={notification} />
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">{recipient}</div>
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">{subject}</div>
      </Col>
      <Col md="4" lg="4" className={cellClasses}>
        <NotificationStatus className="px-3 py-2" status={status} />
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">
          {formatRelative(parseISO(lastStatusDate), new Date())}
        </div>
      </Col>
    </Row>
  );
}

function SmsRow({ notification }) {
  const { recipient, status, lastStatusDate } = notification;

  return (
    <Row
      className="d-flex align-items-stretch align-content-stretch border-bottom border-gray-400"
      noGutters
    >
      <Col md="2" lg="2" className={cellClasses}>
        <NotificationTemplate notification={notification} />
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">{recipient}</div>
      </Col>
      <Col md="4" lg="4" className={cellClasses}>
        <NotificationStatus className="px-3 py-2" status={status} />
      </Col>
      <Col className={cellClasses}>
        <div className="px-3 py-2">
          {formatRelative(parseISO(lastStatusDate), new Date())}
        </div>
      </Col>
    </Row>
  );
}
