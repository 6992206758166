import React, { useEffect, useRef } from 'react';
import {
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Alert
} from 'reactstrap';
import { ButtonLoading } from '../../../components';
import { LabelField, CreatableSelect } from '../../../components/formik';
import { Form as FormikForm, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import { titleCase } from '../../../utils';
import { useActions, useValues } from 'kea';
import { categoriesLogic, newTemplateLogic } from '../../../kea-logic';

export default function Form(props) {
  const {
    handleSubmit,
    errors,
    touched,
    templateErrors,
    setFieldError,
    isSubmitting,
    currentTemplateGroup,
    resetForm,
    setFieldValue
  } = props;
  const history = useHistory();
  const nameInput = useRef(null);
  const modalRef = useRef(null);
  const { toggle } = useActions(newTemplateLogic);
  const { isOpen } = useValues(newTemplateLogic);
  const { categoryOptions, loading, creating, selectedCategory } = useValues(
    categoriesLogic
  );
  const { createCategory } = useActions(categoriesLogic);

  useEffect(() => {
    if (templateErrors) {
      Object.keys(templateErrors).map(error =>
        setFieldError(error, `${titleCase(error)} ${templateErrors[error]}`)
      );
    } // eslint-disable-next-line
  }, [templateErrors]);

  useEffect(() => {
    if (currentTemplateGroup && currentTemplateGroup.id) {
      toggle();
      localStorage.setItem('activeTab', JSON.stringify('1'));
      history.push(`/templates/${currentTemplateGroup.id}`);
    } // eslint-disable-next-line
  }, [currentTemplateGroup]);

  useEffect(() => {
    resetForm();
  }, [resetForm, toggle]);

  useEffect(() => {
    if (selectedCategory) {
      const { name: label, id: value } = selectedCategory;
      setFieldValue('category', { label, value });
    }
  }, [setFieldValue, selectedCategory]);

  return (
    <FormikForm>
      <Modal
        innerRef={modalRef}
        isOpen={isOpen}
        onOpened={() => {
          nameInput.current.focus();
        }}
        scrollable
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>New Template</ModalHeader>
        <ModalBody>
          {templateErrors && templateErrors.length > 0 && (
            <Alert color="danger">
              {templateErrors.map(error => (
                <div>{error}</div>
              ))}
            </Alert>
          )}
          <FormGroup>
            <LabelField labelName="Name" fieldName="name" required />
            <Field
              name="name"
              type="text"
              placeholder="Enter a name for this template"
              invalid={errors['name'] && touched['name']}
              as={Input}
              innerRef={nameInput}
            />
            <ErrorMessage name="name" component={FormFeedback} />
          </FormGroup>
          <CreatableSelect
            name="category"
            label="Category"
            optional
            options={categoryOptions}
            isClearable
            isLoading={loading || creating}
            isDisabled={loading || creating}
            placeholder="Select or create a category..."
            onCreateOption={async option => {
              await createCategory(option);
            }}
            menuPortalTarget={modalRef.current || null}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" outline size="sm" onClick={toggle}>
            Cancel
          </Button>
          <ButtonLoading
            isLoading={isSubmitting}
            color="success"
            size="sm"
            onClick={handleSubmit}
          >
            Create Template
          </ButtonLoading>
        </ModalFooter>
      </Modal>
    </FormikForm>
  );
}
