const domain =
  process.env.REACT_APP_OF_AUTH0__DOMAIN || 'staging-oforce.auth0.com';

const clientId =
  process.env.REACT_APP_OF_AUTH0__CLIENT_ID ||
  'pBCSTur6BeXHwfq66PM86VpW5PXG7RBa';

const audience =
  process.env.REACT_APP_OF_AUTH0__AUDIENCE ||
  'https://staging-stmapi.oforce.com';

const config = {
  domain,
  clientId,
  audience,
  scope: 'openid profile email',
};

export default config;
