import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function TemplateHelp({ showSms }) {
  return (
    <div className="mb-3">
      <div className="p-3 bg-light rounded">
        <div className="small">
          <span style={{ fontWeight: 500 }}>
            <FontAwesomeIcon
              icon={['far', 'subscript']}
              className="mr-1 mb-1"
              fixedWidth
            />
            Variables{' '}
          </span>
          <code className="p-1 bg-light">{`<%= @variable_name %>`}</code>
        </div>
        <div className="small mt-2">
          <span style={{ fontWeight: 500 }}>
            <FontAwesomeIcon
              icon={['far', 'sitemap']}
              className="mr-1 mb-1"
              fixedWidth
            />
            Conditional Rendering{' '}
          </span>
          <code className="p-1 bg-light">
            {`<%= if @boolean_name do %>Show Me<% else %>Show This<% end %>`}
          </code>
        </div>
        {showSms && (
          <div className="small mt-2">
            <FontAwesomeIcon
              fixedWidth
              icon="exclamation-triangle"
              className="mr-1 mb-1"
            />
            <span style={{ fontWeight: 500 }}>Note</span> - Character count
            becomes inaccurate when using variables. This may result in your SMS
            being split into multiple messages if the message is too long.
          </div>
        )}
      </div>
    </div>
  );
}
