import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const CopyButton = styled(FontAwesomeIcon).attrs({
  className: 'ml-2 mb-1 text-info btn btn-link p-0'
})`
  cursor: pointer;
`;

export default function CopyableField ({ text, className, style, id }) {
  const [timeoutId, setTimeoutId] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const copyText = useRef(null);
  const uniqueId = `a${id}`;

  const setTooltipTimeout = () => setTimeout(() => setShowTooltip(false), 1500);

  const copy = () => {
    copyText.current.select();
    document.execCommand('copy');

    clearTimeout(timeoutId);
    setShowTooltip(true);
    setTimeoutId(setTooltipTimeout());
  };

  useEffect(() => () => clearTimeout(timeoutId));

  return (
    <>
      <Tooltip
        placement="top"
        isOpen={showTooltip}
        autohide={false}
        target={uniqueId}
      >
        Copied!
      </Tooltip>
      <span
        className={`${className} d-inline-block`}
        id={uniqueId}
        style={style}
      >
        {text}
      </span>
      <input
        type="text"
        value={text}
        ref={copyText}
        readOnly
        style={{ position: 'absolute', left: '-10000px', top: '-10000px' }}
      />
      <CopyButton icon={['far', 'copy']} onClick={copy} />
    </>
  );
}
