import React from 'react';
import { transparentize } from 'polished';
import styled from 'styled-components';

const StyledDescription = styled.div.attrs({ className: 'small' })`
  color: ${props => props.theme.gray600};
  white-space: normal;
`;

const StyledLabel = styled.div`
  font-weight: 500;
`;

const StyledCustomOption = styled.div.attrs(props => {
  return {
    className: `p-2 ${props.active && 'active'}`
  };
})`
  cursor: default;
  &:hover {
    background: ${props => transparentize(0.9, props.theme.blue)};
  }
  &:active,
  &.active {
    background: ${props => props.theme.blue};
    color: white;
    ${StyledDescription} {
      color: white;
    }
  }
`;

export const CustomOption = ({ data, innerProps, isSelected }) => (
  <StyledCustomOption {...innerProps} active={isSelected}>
    <StyledLabel>{data.label}</StyledLabel>
    <StyledDescription>{data.description}</StyledDescription>
  </StyledCustomOption>
);
