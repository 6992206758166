import React from 'react';
import { Button } from '@oforce/global-components';

export default function PagerButtons({
  loading,
  hasNextPage,
  hasPreviousPage,
  loadNextPage,
  loadPreviousPage
}) {
  return (
    <div className="d-flex justify-content-between mt-2 mr-1 ml-1">
      <Button
        color="link"
        onClick={loadPreviousPage}
        disabled={!hasPreviousPage}
        loading={loading}
        loadingText="Loading..."
      >
        Previous Page
      </Button>
      <Button
        color="link"
        onClick={loadNextPage}
        disabled={!hasNextPage}
        loading={loading}
        loadingText="Loading..."
      >
        Next Page
      </Button>
    </div>
  );
}
