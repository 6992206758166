import { Label } from '@oforce/global-components';
import React from 'react';
import root from 'react-shadow';

export default function Preview({ shell, message, data }) {
  const renderedMessage = Object.keys(data).reduce((acc, prop) => {
    return acc.replace(new RegExp(`<%= @${prop} %>`, 'g'), data[prop]);
  }, message);

  if(!shell) return null;

  return (
    <div className="mt-3">
      <Label>Preview</Label>
      <div className="d-flex flex-fill border border-gray-400 bg-white">
        <root.div>
          <div
            dangerouslySetInnerHTML={{
              __html: shell.replace(/(<%= @body %>)/g, renderedMessage),
            }}
          ></div>
        </root.div>
      </div>
    </div>
  );
}
