import React, { useState } from 'react';
import { Formik } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { GraphQLErrors } from '../';
import { RECIPIENT_MODE } from './hooks/useRecipientMode';
import {
  useRecipientMode,
  useSelectedTemplateGroup,
  useSendNotification,
} from './hooks';

import {
  CancelButton,
  SendButton,
  TemplateSelect,
  RecipientsInput,
  TemplateRenderer,
  Scheduler,
} from './form';

export default function SendNotification({ type, isOpen, toggle }) {
  const [recipientMode, setRecipientMode] = useRecipientMode(
    RECIPIENT_MODE.INPUT
  );
  const [template, setTemplate] = useState(null);

  const {
    validationSchema,
    initialValues,
    handleSubmit,
    loading,
    error,
    reset,
  } = useSendNotification({ type, recipientMode, onCompleted: toggle });

  const onModalClosed = (formik) => () => {
    formik.resetForm();
    setTemplate(null);
    reset();
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <Modal
            scrollable
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onModalClosed(formik)}
          >
            <ModalHeader toggle={toggle}>
              {`Send ${type} notification`}
            </ModalHeader>

            <ModalBody style={{ minHeight: 500 }}>
              <GraphQLErrors error={error} />
              <TemplateSelect type={type} onChange={setTemplate} />
              {template && (
                <TemplateForm
                  template={template}
                  type={type}
                  formik={formik}
                  recipientMode={recipientMode}
                  setRecipientMode={setRecipientMode}
                />
              )}
            </ModalBody>

            <ModalFooter className="d-flex justify-content-between">
              <CancelButton loading={loading} toggle={toggle} />
              <SendButton
                loading={loading}
                onSend={formik.handleSubmit}
                disabled={!formik.isValid}
              />
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
}

function TemplateForm({
  template,
  type,
  formik,
  recipientMode,
  setRecipientMode,
}) {
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const { variables, shell, message, loading } = useSelectedTemplateGroup({
    id: template.value.id,
    type,
  });

  if (loading) return null;

  return (
    <>
      <RecipientsInput
        type={type}
        formik={formik}
        variables={variables}
        onInputModeChanged={setRecipientMode}
        onRecipientSelected={setSelectedRecipient}
      />
      <TemplateRenderer
        type={type}
        formik={formik}
        shell={shell}
        message={message}
        variables={variables}
        selectedRecipient={selectedRecipient}
        showVariableEditor={recipientMode === RECIPIENT_MODE.INPUT}
      />
      <hr />
      <Scheduler formik={formik} />{' '}
    </>
  );
}
