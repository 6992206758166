import React from 'react';
import { Row, Col } from 'reactstrap';
import { NOTIFICATION_TYPE } from './useNotifications';

export default function NotificationsTableHeader({ type }) {
  return type === NOTIFICATION_TYPE.EMAIL ? <EmailHeader /> : <SmsHeader />;
}

const EmailHeader = () => (
  <Row
    className="d-flex align-items-left border-bottom border-width-2 border-gray-400"
    noGutters
  >
    <Col md="2" lg="2">
      <ColumnText>Template</ColumnText>
    </Col>
    <Col md="2" lg="2">
      <ColumnText>Name</ColumnText>
    </Col>
    <Col md="2" lg="2">
      <ColumnText>Subject</ColumnText>
    </Col>
    <Col md="4" lg="4">
      <ColumnText>Status</ColumnText>
    </Col>
    <Col md="2" lg="2">
      <ColumnText>Status Date</ColumnText>
    </Col>
  </Row>
);

const SmsHeader = () => (
  <Row
    className="d-flex align-items-left border-bottom border-width-2 border-gray-400"
    noGutters
  >
    <Col md="2" lg="2">
      <ColumnText>Template</ColumnText>
    </Col>
    <Col md="2" lg="2">
      <ColumnText>Number</ColumnText>
    </Col>
    <Col md="4" lg="4">
      <ColumnText>Status</ColumnText>
    </Col>
    <Col>
      <ColumnText>Send Date</ColumnText>
    </Col>
  </Row>
);

const ColumnText = ({ children }) => (
  <div
    className="bg-white text-gray-700 border-gray-400 px-3 py-2 border-right flex-fill"
    style={{ fontWeight: 500 }}
  >
    {children}
  </div>
);
