import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import config from './config';
import cache from './Cache';

export default function AuthProvider({ children }) {
  const history = useHistory();

  const providerConfig = {
    ...config,
    cache,
    redirectUri: window.location.origin,
    onRedirectCallback: (appState) => {
      history.push(appState?.returnTo || window.location.pathname);
    },
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
}
