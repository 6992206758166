import React, { useRef } from 'react';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Alert
} from 'reactstrap';
import { ButtonLoading } from '../../../../../components';
import { LabelField } from '../../../../../components/formik';
import { Form as FormikForm, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { testSendLogic } from '..';
import { useActions, useValues } from 'kea';

export default function Form({ handleSubmit, errors, touched, isSubmitting }) {
  const recipientInputEl = useRef(null);
  const { toggleSmsModal } = useActions(testSendLogic);
  const { showSmsModal, sent, errors: sendErrors } = useValues(testSendLogic);

  return (
    <FormikForm>
      <Modal isOpen={showSmsModal} toggle={toggleSmsModal} scrollable>
        <ModalHeader toggle={toggleSmsModal}>Send Test SMS</ModalHeader>
        <ModalBody>
          <fieldset disabled={isSubmitting}>
            {sent && typeof sent === "string" && (
              <Alert color="success">
                Your test SMS has been sent to {sent}.
              </Alert>
            )}
            {errors && sendErrors.length > 0 && (
              <Alert color="danger">
                {sendErrors.map(error => (
                  <div>{error}</div>
                ))}
              </Alert>
            )}

            <LabelField
              labelName="Recipient Phone"
              fieldName="recipient"
              required
            />
            <Field
              name="recipient"
              type="phone"
              placeholder="Enter the recipient's phone number"
              invalid={errors['recipient'] && touched['recipient']}
              as={Input}
              innerRef={recipientInputEl}
            />
            <ErrorMessage name="recipient" component={FormFeedback} />
          </fieldset>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" outline size="sm" onClick={toggleSmsModal}>
            Cancel
          </Button>
          <ButtonLoading
            isLoading={isSubmitting}
            loadingText="Sending..."
            color="info"
            size="sm"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon="sms" className="mr-2" />
            Send Test SMS
          </ButtonLoading>
        </ModalFooter>
      </Modal>
    </FormikForm>
  );
}
