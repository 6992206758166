import React from 'react';

const Placeholder = ({ children, className, textAlign = 'center' }) => {
  return (
    <div className={`${className} text-${textAlign} text-gray-500 font-italic`}>
      {children}
    </div>
  );
};

export default Placeholder;
