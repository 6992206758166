import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { NOTIFICATION_TYPE } from '../../notifications-table/useNotifications';

const TEMPLATE_SHELLS = gql`
  query shells {
    smsShell
    emailShell
  }
`;

const GET_TEMPLATE_GROUP = gql`
  query templateGroup($id: ID!) {
    templateGroup(id: $id) {
      id
      emailEnabled
      emailTemplate {
        subject
        message
        variables
      }
      smsEnabled
      smsTemplate {
        variables
        message
      }
    }
  }
`;

export default function useSelectedTemplateGroup({ type, id }) {
  const { data: shells } = useQuery(TEMPLATE_SHELLS);

  const { data, loading } = useQuery(GET_TEMPLATE_GROUP, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  const shell = useMemo(() => {
    return type === NOTIFICATION_TYPE.EMAIL
      ? shells?.emailShell
      : shells?.smsShell;
  }, [shells, type]);

  const [variables, message, subject] = useMemo(() => {
      const template = data?.templateGroup;

    if (type === NOTIFICATION_TYPE.EMAIL) {
      return [
        template?.emailTemplate?.variables,
        template?.emailTemplate?.message,
        template?.emailTemplate?.subject,
      ];
    } else if (type === NOTIFICATION_TYPE.SMS) {
      return [
        template?.smsTemplate?.variables,
        template?.smsTemplate?.message,
        null,
      ];
    }
  }, [data, type]);

  return {
    shell,
    loading,
    variables: variables || {},
    message,
    subject,
  };
}
