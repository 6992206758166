import React from 'react';
import styled from 'styled-components';
import { Placeholder } from '../../../../components';
import { templatesLogic } from '../../../../kea-logic';
import { useValues } from 'kea';

const StyledMessageBubble = styled.div.attrs({
  className: 'px-3 py-2 d-flex flex-fill'
})`
  white-space: pre-wrap;
  border-radius: 10px;
  background: ${props => props.theme.gray200};
  max-width: 250px;
`;

export default function Preview({ message }) {
  const { templateShells } = useValues(templatesLogic);

  return message ? (
    <div className="p-3">
      <div className="small text-gray-500">Openforce</div>
      <StyledMessageBubble>
        {templateShells.sms.replace(/(<%= @body %>)/g, message)}
      </StyledMessageBubble>
    </div>
  ) : (
    <Placeholder className="bg-light p-3">No template</Placeholder>
  );
}
