import React from 'react';
import Select from 'react-select';
import { useReactSelectStyles } from '.';

export default function ReactSelect(props) {
  const { customTheme, customClassName, customStyles } = useReactSelectStyles(
    props
  );
  return (
    <Select
      {...props}
      theme={customTheme}
      className={customClassName}
      styles={customStyles}
    />
  );
}
