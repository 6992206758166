import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

export default function useResettableMutation(query, props) {
  const [{ loading, data, error, called }, setState] = useState({
      called: false,
      loading: false
    }),
    onCompleted = data => {
      setState({ data, loading: false, called, error });
      props.onCompleted && props.onCompleted(data);
    },
    onError = error => {
      setState({ loading: false, called, error });
      props.onError && props.onError(error);
    },
    reset = () => {
      setState({ data: undefined, loading: false, called: false, error: undefined });
    };

  const [mutate, { client }] = useMutation(query, {
    ...props,
    onCompleted,
    onError
  });

  const mutateWrapper = opts => {
    setState({ data: undefined, loading: true, called: true, error: undefined });
    return mutate(opts);
  };

  return [mutateWrapper, { data, loading, error, called, client, reset }];
}
