import React from 'react';
import { Row, Col } from 'reactstrap';

export default function ListHeader() {
  return (
    <Row
      className="d-flex align-items-left border-bottom border-width-2 border-gray-400"
      noGutters
    >
      <Col md="2" lg="2">
        <ColumnText>Category</ColumnText>
      </Col>
      <Col md="2" lg="2">
        <ColumnText>Template</ColumnText>
      </Col>
      <Col md="2" lg="2">
        <ColumnText>Id</ColumnText>
      </Col>
      <Col md="2" lg="2">
        <ColumnText>Email enabled</ColumnText>
      </Col>
      <Col md="2" lg="2">
        <ColumnText>SMS enabled</ColumnText>
      </Col>
      <Col md="2" lg="2">
        <ColumnText>Prevent Spam</ColumnText>
      </Col>
    </Row>
  );
}

const ColumnText = ({ children }) => (
  <div
    className="bg-white text-gray-700 border-gray-400 px-3 py-2 border-right flex-fill"
    style={{ fontWeight: 500 }}
  >
    {children}
  </div>
);
