import React from 'react';
import styled from 'styled-components';

const StyledTab = styled.div.attrs({ className: 'py-2 mr-3' })`
  border-bottom: 2px solid white;
  cursor: pointer;
  color: ${props => props.theme.gray600};
  user-select: none;

  &:hover {
    border-color: ${props => props.theme.gray300};
    color: ${props => props.theme.gray800};
  }

  &${props => (props.active ? false : true)}, &:active {
    color: ${props => props.theme.blue} !important;
    border-color: ${props => props.theme.blue} !important;
  }
`;

export default function Tab(props) {
  return <StyledTab {...props} />;
}
