import React from 'react';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

NotificationTemplate.fragments = {
  NotificationTemplate_Notification: gql`
    fragment NotificationTemplate_Notification on Notification {
      template {
        id: systemId
        name
      }
    }
  `,
};

export default function NotificationTemplate({ notification }) {
  const { template } = notification;

  if (!template) return null;
  return (
    <div className="px-3 py-2">
      <Button
        color="link"
        tag={Link}
        to={`/templates/${template.id}`}
        className="text-info p-0"
      >
        {template.name}
      </Button>
    </div>
  );
}
