import { Label } from '@oforce/global-components';
import React from 'react';

const Switch = ({ id, label, value, disabled, ...rest }) => {
  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        disabled={disabled}
        defaultChecked={value}
        {...rest}
      />
      <Label className="custom-control-label" htmlFor={id}>
        {label}
      </Label>
    </div>
  );
};

export default Switch;
