import React from 'react';
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Button
} from 'reactstrap';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import { ButtonLoading, TemplateHelp } from '../../../../components';
import { LabelField } from '../../../../components/formik';
import * as yup from 'yup';
import { useActions, useValues } from 'kea';
import { templatesLogic } from '../../../../kea-logic';
import { testSendLogic } from '../SendTest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SmsPreview } from '../SmsTemplate';

export default function Form({ templateGroup }) {
  const { updateTemplateGroup } = useActions(templatesLogic);
  const { toggleSmsModal } = useActions(testSendLogic);
  const { updating } = useValues(templatesLogic);
  const { id, sms_enabled, sms_template } = templateGroup;

  const initialValues = {
    sms_enabled,
    message: (sms_template && sms_template.message) || ''
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={yup.object().shape({
        sms_enabled: yup.boolean(),
        message: yup.string().required('Required')
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const { sms_enabled, ...rest } = values;
        await updateTemplateGroup({
          id,
          sms_enabled,
          sms_template: rest
        });
        setSubmitting(false);
      }}
    >
      {({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        values,
        dirty,
        setFieldValue
      }) => {
        return (
          <FormikForm>
            <Row>
              <Col xs="12" md="6" className="mb-5 mb-md-0">
                <fieldset disabled={isSubmitting || updating}>
                  <FormGroup className="mb-1">
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <div>
                        <LabelField
                          labelName="Message"
                          fieldName="message"
                          required
                          className="d-inline-block mb-0"
                        />
                        <span className="text-muted small ml-2">
                          ({values.message.length}/160 characters)
                        </span>
                      </div>
                      <Button
                        color="link"
                        className="text-info p-0"
                        onClick={e => {
                          e.preventDefault();
                          setFieldValue(
                            'message',
                            values.message + ' <%= @VARIABLE %>'
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'plus']}
                          className="mr-1 mb-1"
                        />
                        Variable
                      </Button>
                    </div>
                    <Field
                      name="message"
                      as={Input}
                      type="textarea"
                      style={{ minHeight: '110px' }}
                      placeholder="Enter SMS message"
                      invalid={errors['message'] && touched['message']}
                    />
                    <ErrorMessage name="message" component={FormFeedback} />
                  </FormGroup>
                </fieldset>
                <TemplateHelp showSms />
                <div className="d-flex justify-content-end">
                  <ButtonLoading
                    isLoading={isSubmitting || updating}
                    color="success"
                    size="sm"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!dirty}
                  >
                    Save Changes
                  </ButtonLoading>
                </div>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column align-items-stretch"
              >
                <div className="d-flex justify-content-between">
                  <Label className="font-weight-bold mb-1">SMS Preview</Label>
                  <Button
                    color="link"
                    className="text-info p-0"
                    disabled={dirty}
                    onClick={toggleSmsModal}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'mobile']}
                      className="mr-2 mb-1"
                    />
                    Send Test SMS
                  </Button>
                </div>
                <div className="d-flex flex-fill border border-gray-400 h-100">
                  <SmsPreview message={values.message} />
                </div>
              </Col>
            </Row>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
