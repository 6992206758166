import React from 'react';
import { FormGroup } from 'reactstrap';
import { ReactSelect } from '../../../components/ReactSelect';
import { unsubscribeGroupsLogic, templatesLogic } from '../../../kea-logic';
import { useValues, useActions } from 'kea';
import { CustomOption } from '../../../components/ReactSelect';

export default function ChangeUnsubscribeGroup({
  unsubscribeGroup,
  templateGroup
}) {
  const { updating } = useValues(templatesLogic);
  const { unsubscribeGroupOptions, loading } = useValues(
    unsubscribeGroupsLogic
  );
  const { changeUnsubscribeGroup } = useActions(templatesLogic);

  const selectedOption =
    unsubscribeGroup &&
    unsubscribeGroupOptions &&
    unsubscribeGroupOptions.find(x => x.value === unsubscribeGroup);

  return (
    <FormGroup className="mb-0">
      <ReactSelect
        options={unsubscribeGroupOptions}
        value={selectedOption}
        isClearable
        isLoading={loading || updating}
        isDisabled={loading || updating || !unsubscribeGroupOptions}
        placeholder="Change unsubscribe group..."
        onChange={option => changeUnsubscribeGroup(option, templateGroup)}
        onClear={option => changeUnsubscribeGroup(option, templateGroup)}
        components={{ Option: CustomOption }}
      />
    </FormGroup>
  );
}
