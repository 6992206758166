import React from 'react';
import { Button } from 'reactstrap';
import { Loader, Placeholder } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { TemplateGroupForm } from '../TemplateGroup';
import { useValues } from 'kea';
import { templatesLogic } from '../../../kea-logic';

export default function View() {
  const { templateGroups, loading } = useValues(templatesLogic);
  const { id } = useParams();
  const templateGroup = templateGroups[id] || { name: '' };

  if (loading) return <Loader />;

  return (
    <>
      <BackButton />
      {!!templateGroup ? (
        <TemplateGroupForm templateGroup={templateGroup} />
      ) : (
        <Placeholder>No template group found</Placeholder>
      )}
    </>
  );
}

const BackButton = () => (
  <Button
    color="link"
    tag={Link}
    to="/templates"
    className="text-info p-0 mb-2"
  >
    <FontAwesomeIcon icon={['far', 'long-arrow-alt-left']} className="mr-2" />
    Back to Templates
  </Button>
);
