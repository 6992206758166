import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { resetContext, getContext } from 'kea';
import listenersPlugin from 'kea-listeners';

import { theme } from '@oforce/global-styles';
import App from './App';
import './styles/index.scss';
import './assets/fontawesome';
import '@fortawesome/fontawesome-pro/css/solid.min.css';
import { AuthProvider, authCache } from './components/auth';

resetContext({
  createStore: {
    paths: ['oforce'],
  },
  plugins: [listenersPlugin],
});

const customFetch = (_uri, options) => {
  const token = authCache.getAccessToken();
  
  if (token) {
    options.headers['authorization'] = `Bearer ${token}`;
  }

  return fetch('/api', options);
};

const httpLink = new HttpLink({ fetch: customFetch });

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

ReactDOM.render(
  <Provider store={getContext().store}>
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
