import { DatePicker } from 'antd';
import { Switch } from '../../../components';
import React, { useState } from 'react';
import { useToggle } from '@oforce/global-components';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  align-items: center;
  min-height: 35px;
`;

export default function Scheduler({ formik }) {
  const [chooseDate, toggleChooseDate] = useToggle();
  const [date, setDate] = useState(null);

  const onScheduleChanged = (moment) => {
    setDate(moment);
    formik.setFieldValue('scheduledAt', moment.format());
  };

  const disabledDate = (current) => current && current < moment();

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between">
        <div className="w-100">
          <StyledRow>
            <Col>
              <Switch
                id="schedule-notification"
                label="Schedule this Notification"
                checked={chooseDate}
                onChange={toggleChooseDate}
              />
            </Col>
            <Col>
              {chooseDate && (
                <DatePicker
                  showTime
                  use12Hours
                  minuteStep={15}
                  className="float-right"
                  defaultValue={date}
                  disabledDate={disabledDate}
                  format="MM/DD/YYYY h:mm a"
                  id="scheduledAt"
                  name="scheduledAt"
                  onChange={onScheduleChanged}
                />
              )}
            </Col>
          </StyledRow>
        </div>
      </div>
    </div>
  );
}
