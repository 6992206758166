import React from 'react';
import { Placeholder, Loader } from '../../components';
import NotificationsTableHeader from './NotificationsTableHeader';
import NotificationRow from './NotificationRow';
import useNotifications from './useNotifications';
import PagerButtons from '../PagerButtons';

export default function NotificationTable({ type }) {
  const {
    notifications,
    loading,
    hasNextPage,
    hasPreviousPage,
    loadNextPage,
    loadPreviousPage,
  } = useNotifications({ type });

  return (
    <>
      <div className="bg-white border-left border-top border-gray-400 shadow-sm">
        {/* <div className="border-bottom border-right border-gray-400 p-3">
        <>filters</>
      </div> */}
        <NotificationsTableHeader type={type} />

        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationRow
              key={notification.id}
              notification={notification}
            />
          ))
        ) : (
          <div className="border-gray-400 border-right border-bottom">
            {loading ? (
              <Loader />
            ) : (
              <Placeholder className="bg-white shadow-sm p-3 text-center rounded">
                <p className="mb-0">No Notifications sent</p>
              </Placeholder>
            )}
          </div>
        )}
      </div>
      <PagerButtons
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loadNextPage={loadNextPage}
        loadPreviousPage={loadPreviousPage}
        loading={loading}
      />
    </>
  );
}
