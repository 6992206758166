import {
  Button,
  FileInputField,
  Label,
  ReactCreatableSelect,
} from '@oforce/global-components';
import React, { useCallback, useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
import {
  SMS_RECIPIENTS,
  EMAIL_RECIPIENTS,
  parseRecipientsFromBlob,
} from '../../../utils/recipients';
import Switch from '../../Switch';
import { useRecipientTemplateFile, useRecipientOptionsSession } from '../hooks';
import { RECIPIENT_MODE } from '../hooks/useRecipientMode';
import RecipientsTable from './RecipientsTable';

export default function RecipientsInput({
  type,
  formik,
  variables,
  onInputModeChanged,
  onRecipientSelected,
}) {
  const [importRecipients, setImportRecipients] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const { options, setOptions, recipientsToOptions } =
    useRecipientOptionsSession({ type });

  const recipientsImported = useCallback(
    (recipients) => {
      setOptions(recipients || []);
      setRecipients(recipientsToOptions(recipients || []));
    },
    [recipientsToOptions, setOptions]
  );

  useEffect(() => {
    formik.setFieldValue('recipients', recipients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipients]);

  useEffect(() => {
    onInputModeChanged(
      importRecipients ? RECIPIENT_MODE.IMPORT : RECIPIENT_MODE.INPUT
    );
  }, [importRecipients, onInputModeChanged]);

  return (
    <FormGroup>
      <div className="d-flex align-items-center mb-2 justify-content-between">
        <Label className="m-0 mt-1">Recipients</Label>
        <Switch
          id={`import-recipients-switch`}
          label="Import from file"
          checked={importRecipients}
          disabled={recipients && recipients.length > 0}
          onChange={() => setImportRecipients(!importRecipients)}
        />
      </div>
      {importRecipients ? (
        <ImportRecipients
          type={type}
          variables={variables}
          recipients={recipients}
          onChange={recipientsImported}
          onRecipientSelected={onRecipientSelected}
        />
      ) : (
        <ReactCreatableSelect
          isClearable={true}
          name="recipients"
          onChange={(option) => {
            const options = option ? [option] : [];
            setOptions(options);
            setRecipients(options);
          }}
          options={options}
          placeholder="Add Recipients..."
          className=""
        />
      )}
    </FormGroup>
  );
}

function ImportRecipients({
  type,
  onChange,
  recipients,
  onRecipientSelected,
  variables,
}) {
  const downloadTemplateFile = useRecipientTemplateFile({ type, variables });

  const readFile = useCallback(
    ({ target }) => {
      const file = target.files[0];
      parseRecipientsFromBlob(file, { include: type }).then((recipients) => {
        onChange(recipients);
      });
    },
    [onChange, type]
  );
  return (
    <>
      <Button onClick={downloadTemplateFile} color="link">
        Download Template File
      </Button>
      <FileInputField
        required
        accept=".csv"
        id="recipients"
        name="recipients"
        onChange={readFile}
        placeholder={'Choose CSV file'}
      />
      {recipients && recipients.length > 0 && (
        <>
          <Label className="mt-n2">Sending To</Label>
          <div
            style={{ maxHeight: '200px', overflowY: 'auto' }}
            className={'rounded border border-gray-400'}
          >
            <RecipientsTable
              recipients={recipients}
              onSelected={onRecipientSelected}
            />
          </div>
        </>
      )}
    </>
  );
}

export { SMS_RECIPIENTS, EMAIL_RECIPIENTS };
