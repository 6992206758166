import config from './config';
import { LocalStorageCache } from '@auth0/auth0-react';

const { clientId, audience, scope } = config;

const prefix = '@@auth0spajs@@';
const tokenKey = `${prefix}::${clientId}::${audience}::${scope}`;

const cache = new LocalStorageCache();

const finalCache = {
  get: (key) => {
    return cache.get(key);
  },
  set: (key, value) => {
    return cache.set(key, value);
  },
  getAccessToken: () => {
    const value = cache.get(tokenKey);
    return value?.body?.access_token;
  },
  remove: (key) => cache.remove(key),
};

export default finalCache;
