import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import { NewTemplate, TemplateGroup, List } from '.';
import { useActions } from 'kea';
import {
  templatesLogic,
  categoriesLogic,
  unsubscribeGroupsLogic,
} from '../../kea-logic';
import { newTemplateLogic } from '../../kea-logic';

export default function Templates() {
  const { getTemplateGroups } = useActions(templatesLogic);
  const { getCategories } = useActions(categoriesLogic);
  const { getUnsubscribeGroups } = useActions(unsubscribeGroupsLogic);
  const { toggle } = useActions(newTemplateLogic);

  useEffect(() => {
    getTemplateGroups();
  }, [getTemplateGroups]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getUnsubscribeGroups();
  }, [getUnsubscribeGroups]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h1 className="h4 mb-0 d-inline-block">Templates</h1>
        <Button color="info" size="sm" onClick={toggle}>
          <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
          New Template
        </Button>
      </div>
      <NewTemplate />

      <Switch>
        <Route exact path="/templates">
          <List />
        </Route>
        <Route path={`/templates/:id`}>
          <TemplateGroup />
        </Route>
      </Switch>
    </div>
  );
}
