import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

export default function LabelField(props) {
  const { labelName, fieldName, required, optional, className } = props;
  return (
    <Label className={`font-weight-bold mb-1 ${className}`} for={fieldName}>
      {labelName}
      {required && <small className="text-danger ml-1">*</small>}
      {optional && <small className="text-muted ml-1">{`(optional)`}</small>}
    </Label>
  );
}

LabelField.propTypes = {
  labelName: PropTypes.string,
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  optional: PropTypes.bool
};

LabelField.defaultProps = {
  labelName: '',
  fieldName: '',
  required: false,
  optional: false
};
