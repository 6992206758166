import React, { useState } from 'react';
import VariableEditor from './VariableEditor';
import Preview from './Preview';

export default function TemplateRenderer({
  type,
  formik,
  showVariableEditor,
  selectedRecipient,
  shell,
  message,
  variables,
}) {
  const {
    values: { template },
  } = formik;
  const templateId = template?.value?.id;

  const [data, setData] = useState({});

  if (!templateId) return null;

  return (
    <div className="mt-3">
      {showVariableEditor && (
        <VariableEditor
          type={type}
          variables={variables}
          formik={formik}
          onChange={setData}
        />
      )}
      <Preview
        shell={shell}
        message={message}
        data={selectedRecipient?.data || data}
      />
    </div>
  );
}
