import { kea } from 'kea';

export default kea({
  path: () => ['oforce', 'categories'],
  actions: () => ({
    getCategories: () => ({}),
    setCategories: categories => categories,
    setErrors: (type, errors) => ({ type, errors }),
    setCategoryFilter: category =>
      category ? { name: category.label, id: category.value } : {},
    clearCategoryFilter: () => ({}),
    createCategory: category => category,
    categoryCreated: category => category
  }),
  reducers: ({ actions }) => ({
    categories: [
      [],
      {
        [actions.setCategories]: (state, categories) => [
          ...state,
          ...categories
        ],
        [actions.categoryCreated]: (state, category) => [...state, category]
      }
    ],
    categoryFilter: [
      {},
      {
        [actions.setCategoryFilter]: (_state, category) => category,
        [actions.clearCategoryFilter]: () => {}
      }
    ],
    selectedCategory: [
      null,
      {
        [actions.categoryCreated]: (_state, category) => category
      }
    ],
    loading: [
      false,
      {
        [actions.getCategories]: () => true,
        [actions.setCategories]: () => false
      }
    ],
    creating: [
      false,
      {
        [actions.createCategory]: () => true,
        [actions.categoryCreated]: () => false
      }
    ],
    errors: [
      [],
      {
        [actions.setErrors]: (_state, errors) => errors
      }
    ]
  }),
  listeners: ({ actions }) => ({
    [actions.getCategories]: async () => {
      const url = `/notifications/categories`;
      const opts = { method: 'GET', credentials: 'include' };
      const { data } = await fetch(url, opts).then(x => x.json());
      actions.setCategories(data);
    },
    [actions.createCategory]: async category => {
      const url = `/notifications/categories`;
      const opts = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: category })
      };
      const { data } = await fetch(url, opts).then(x => x.json());
      actions.categoryCreated(data);
    }
  }),
  selectors: ({ selectors }) => ({
    categoryOptions: [
      () => [selectors.categories],
      categories => [
        { label: 'Categories', options: optionizeCategories(categories) }
      ]
    ]
  })
});

const optionizeCategories = categories =>
  categories.reduce(
    (acc, category) => [...acc, { label: category.name, value: category.id }],
    []
  );
