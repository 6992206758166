import { useState } from 'react';

export const RECIPIENT_MODE = {
  IMPORT: 'import',
  INPUT: 'input',
};

export function validateRecipientMode(mode) {
  const validValues = Object.values(RECIPIENT_MODE);
  if (!validValues.includes(mode))
    throw Error(`Invalid recipient mode: '${mode}'`);
}

export function toGraphQlRecipients(recipientMode, recipients) {    
  validateRecipientMode(recipientMode);
  
  return recipients.map(({ data, ...recipient }) => {
    if (recipientMode === RECIPIENT_MODE.IMPORT) {
      return { ...recipient, data: JSON.stringify(data) };
    }
    if (recipientMode === RECIPIENT_MODE.INPUT) {
      return { ...recipient };
    }
    return null;
  })
}

export default function useRecipientMode(defaultMode = RECIPIENT_MODE.INPUT) {
  validateRecipientMode(defaultMode);
  return useState(defaultMode);
}
