import gql from 'graphql-tag';
import { useEffect, useRef, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { debounce } from 'throttle-debounce';

export const GET_TEMPLATES = gql`
  query TemplateGroups($type: NotificationType, $name: String, $first: Int = 10) {
    templateGroups(type: $type, name: $name, first: $first) {
      edges {
        node {
          id
          name
          canonicalName
        }
      }
    }
  }
`;

export default function useTemplateGroups({ type }) {
  const [loadTemplates, { data, loading, error }] = useLazyQuery(
    GET_TEMPLATES,
    { fetchPolicy: 'cache-and-network' }
  );
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadTemplates({ variables: { type, name: searchTerm } });
  }, [loadTemplates, type, searchTerm]);

  const searchName = useRef(
    debounce(500, false, (value) => {
      return setSearchTerm(value);
    })
  );

  const [templates, options] = useMemo(() => {
    const templates = data?.templateGroups?.edges?.map((x) => x.node) || [];

    const options = templates.map((template) => ({
      value: template,
      label: template.name,
    }));

    return [templates, options];
  }, [data]);

  return {
    templates,
    options,
    loading,
    error,
    searchName: searchName.current,
    searchTerm,
  };
}
