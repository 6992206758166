import { kea } from 'kea';

export default kea({
  path: () => ['oforce', 'unsubscribeGroups'],
  actions: () => ({
    getUnsubscribeGroups: () => ({}),
    setUnsubscribeGroups: groups => groups,
    setErrors: (type, errors) => ({ type, errors })
  }),
  reducers: ({ actions }) => ({
    unsubscribeGroups: [
      [],
      {
        [actions.setUnsubscribeGroups]: (state, groups) => [...state, ...groups]
      }
    ],
    loading: [
      false,
      {
        [actions.getUnsubscribeGroups]: () => true,
        [actions.setUnsubscribeGroups]: () => false
      }
    ],
    errors: [
      [],
      {
        [actions.setErrors]: (_state, errors) => errors
      }
    ]
  }),
  listeners: ({ actions }) => ({
    [actions.getUnsubscribeGroups]: async () => {
      const url = `/notifications/unsubscribe-groups`;
      const opts = { method: 'GET', credentials: 'include' };
      const data = await fetch(url, opts).then(x => x.json());
      actions.setUnsubscribeGroups(data);
    }
  }),
  selectors: ({ selectors }) => ({
    unsubscribeGroupOptions: [
      () => [selectors.unsubscribeGroups],
      groups =>
        groups.reduce(
          (acc, group) => [
            ...acc,
            {
              label: group.name,
              value: group.id,
              description: group.description
            }
          ],
          []
        )
    ]
  })
});
