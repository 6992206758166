import React from 'react';
import { Row, Col } from 'reactstrap';
import { ReactSelect } from '../../components/ReactSelect';
import { categoriesLogic } from '../../kea-logic';
import { useActions, useValues } from 'kea';

export default function CategoryFilter() {
  const { setCategoryFilter } = useActions(categoriesLogic);
  const { loading, categoryOptions } = useValues(categoriesLogic);

  return (
    <Row form>
      <Col xs="4">
        <div className="d-flex align-items-center">
          <ReactSelect
            isClearable
            placeholder="Filter by category..."
            isLoading={loading}
            isDisabled={loading}
            options={categoryOptions}
            onChange={setCategoryFilter}
          />
        </div>
      </Col>
    </Row>
  );
}
