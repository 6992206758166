import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Switch, CopyableField, Placeholder } from '../../components';
import { Link } from 'react-router-dom';
import { useActions } from 'kea';
import { templatesLogic } from '../../kea-logic';

export default function Group({ group }) {
  const { updateTemplateGroup } = useActions(templatesLogic);
  const {
    name,
    canonical_name,
    id,
    email_enabled,
    sms_enabled,
    category,
    bypass_spam_detection,
  } = group;

  const cellClasses = 'border-right border-gray-400';

  return (
    <Row
      className="d-flex align-items-stretch align-content-stretch border-bottom border-gray-400"
      noGutters
    >
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">
          {category ? (
            category.name
          ) : (
            <Placeholder textAlign="left">No category</Placeholder>
          )}
        </div>
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">
          <Button
            color="link"
            tag={Link}
            to={`/templates/${id}`}
            className="text-info p-0"
          >
            {name}
          </Button>
        </div>
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div
          className="px-3 py-2"
          style={{
            overflowX: 'auto',
          }}
        >
          <CopyableField className="text-muted" id={id} text={canonical_name} />
        </div>
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2 d-flex align-items-center">
          {group && !!group.email_template ? (
            <Switch
              id={`${id}-email`}
              checked={email_enabled}
              onChange={() =>
                updateTemplateGroup({ id, email_enabled: !email_enabled })
              }
            />
          ) : (
            <Placeholder textAlign="left">No template</Placeholder>
          )}
        </div>
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">
          {group && !!group.sms_template ? (
            <Switch
              id={`${id}-sms`}
              checked={sms_enabled}
              onChange={() =>
                updateTemplateGroup({ id, sms_enabled: !sms_enabled })
              }
            />
          ) : (
            <Placeholder textAlign="left">No template</Placeholder>
          )}
        </div>
      </Col>
      <Col md="2" lg="2" className={cellClasses}>
        <div className="px-3 py-2">
          {group && (!!group.email_template || !!group.sms_template) ? (
            <Switch
              id={`${id}-spam`}
              checked={!bypass_spam_detection}
              onChange={() =>
                updateTemplateGroup({
                  id,
                  bypass_spam_detection: !bypass_spam_detection,
                })
              }
            />
          ) : (
            <Placeholder textAlign="left">No template</Placeholder>
          )}
        </div>
      </Col>
    </Row>
  );
}
