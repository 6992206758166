import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import { NotificationsTable, SendNotification } from '../../components';
import { SMS_RECIPIENTS } from '../../utils/recipients';
import { NOTIFICATION_TYPE } from '../../components/notifications-table/useNotifications';

export default function SmsMessages() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h1 className="h4 mb-0 d-inline-block">SMS Messages</h1>
        <Button color="info" size="sm" onClick={toggle}>
          <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
          Send SMS Message
        </Button>
        <SendNotification isOpen={isOpen} toggle={toggle} type={SMS_RECIPIENTS} />
      </div>

      <Switch>
        <Route exact path="/sms-messages">
          <NotificationsTable type={NOTIFICATION_TYPE.SMS} />
        </Route>
      </Switch>
    </div>
  );
}
