import * as yup from 'yup';
import gql from 'graphql-tag';
import {
  EmailRecipient,
  EMAIL_RECIPIENTS,
  SmsRecipient,
  SMS_RECIPIENTS,
} from '../../../utils/recipients';
import { GET_NOTIFICATIONS } from '../../notifications-table/useNotifications';
import { useResettableMutation } from '../../../hooks';
import { toGraphQlRecipients, validateRecipientMode } from './useRecipientMode';

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($input: SendNotificationInput!) {
    results: sendNotification(input: $input) {
      email
      sms
    }
  }
`;
export default function useSendNotification({
  onCompleted = () => {},
  onError = () => {},
  type,
  recipientMode,
}) {
  validateRecipientMode(recipientMode);

  const [sendNotification, { loading, error, reset }] = useResettableMutation(
    SEND_NOTIFICATION,
    {
      onCompleted,
      onError,
      refetchQueries: [
        { query: GET_NOTIFICATIONS, variables: { first: 25, type } },
      ],
    }
  );

  const validationSchema = yup.object({
    template: yup.object().nullable().required('Required'),
    data: yup.object().nullable(),
    recipients: yup.array().nullable().required('Required'),
    scheduledAt: yup.date().nullable(),
  });

  const initialValues = {
    template: null,
    data: null,
    recipients: null,
    scheduledAt: null,
  };

  const handleSubmit = ({ template, data, recipients, scheduledAt }) => {
    const {
      value: { canonicalName },
    } = template;

    const [emailRecipients, smsRecipients] = readRecipients(recipients);

    const input = {
      templateGroupName: canonicalName,
      data: JSON.stringify(data),
      emailRecipients: toGraphQlRecipients(recipientMode, emailRecipients),
      smsRecipients: toGraphQlRecipients(recipientMode, smsRecipients),
      scheduledAt,
    };

    sendNotification({ variables: { input } });
  };

  const readRecipients = (recipients) => {
    return recipients.reduce(
      (acc, { value: recipient }) => {
        const [email, sms] = acc;
        if (recipient instanceof EmailRecipient) {
          return [[recipient, ...email], sms];
        } else if (recipient instanceof SmsRecipient) {
          return [email, [recipient, ...sms]];
        } else if (type === EMAIL_RECIPIENTS) {
          return [[new EmailRecipient(recipient), ...email], sms];
        } else if (type === SMS_RECIPIENTS) {
          return [email, [new SmsRecipient(recipient), ...sms]];
        } else {
          return acc;
        }
      },
      [[], []]
    );
  };

  return {
    sendNotification,
    handleSubmit,
    validationSchema,
    initialValues,
    loading,
    error,
    reset,
  };
}
