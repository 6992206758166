import React from 'react';
import { Formik } from 'formik';
import { NewTemplateForm } from '.';
import { useActions, useValues } from 'kea';
import { templatesLogic } from '../../../kea-logic';
import * as yup from 'yup';

export default function NewTemplate() {
  const { createTemplateGroup } = useActions(templatesLogic);
  const { errors, currentTemplateGroup } = useValues(templatesLogic);

  return (
    <Formik
      initialValues={{ name: '', category: null }}
      validationSchema={yup.object().shape({
        name: yup.string().required('Required'),
        category: yup.object().nullable()
      })}
      onSubmit={async (values, formikActions) => {
        let { category } = values;
        category = category || {};
        await createTemplateGroup({
          ...values,
          category: { name: category.label, id: category.value },
          sms_enabled: false,
          email_enabled: false
        });
        formikActions.setSubmitting(false);
      }}
    >
      {formikProps => (
        <NewTemplateForm
          {...formikProps}
          templateErrors={errors.create}
          currentTemplateGroup={currentTemplateGroup}
        />
      )}
    </Formik>
  );
}
