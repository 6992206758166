import { kea } from 'kea';

export default kea({
  path: () => ['oforce', 'new-group'],
  actions: () => ({
    toggle: () => ({})
  }),
  reducers: ({ actions }) => ({
    isOpen: [
      false,
      {
        [actions.toggle]: isOpen => !isOpen
      }
    ]
  })
});
