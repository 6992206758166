import { useCallback, useState } from 'react';

export default function useCursorPagination(fetchQuery, { count, ...vars }) {
  const [queryVariables, setQueryVariables] = useState({...vars})
  const loadNextPage = useCallback(
    ({ hasNextPage, endCursor }, variables = {}) => {
      if (hasNextPage) {
        const allVars = {...queryVariables, ...variables}
        setQueryVariables(allVars)
        fetchQuery({
          variables: { ...allVars, first: count, after: endCursor },
        });
      }
    },
    [fetchQuery, count, queryVariables]
  );

  const loadPreviousPage = useCallback(
    ({ hasPreviousPage, startCursor }, variables = {}) => {
      if (hasPreviousPage) {
        const allVars = {...queryVariables, ...variables}
        setQueryVariables(allVars)
        fetchQuery({
          variables: { ...allVars, last: count, before: startCursor },
        });
      }
    },
    [queryVariables, fetchQuery, count]
  );

  return { loadNextPage, loadPreviousPage };
}
