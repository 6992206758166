import React from 'react';
import { Input, FormGroup, FormFeedback } from 'reactstrap';
import { Field, ErrorMessage } from 'formik';
import { LabelField } from '.';

export default function InputField(props) {
  const {
    name,
    label,
    type,
    placeholder,
    errors,
    touched,
    onChange,
    required,
    optional,
    ...rest
  } = props;
  return (
    <FormGroup className="w-100 mb-4">
      {!!label && (
        <LabelField
          labelName={label}
          fieldName={name}
          required={required}
          optional={optional}
        />
      )}
      <Input
        tag={Field}
        type={type}
        name={name}
        invalid={errors[name] && touched[name]}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
      <ErrorMessage name={name} component={FormFeedback} />
    </FormGroup>
  );
}
