import download from 'js-file-download';
import { useCallback, useMemo } from 'react';
import { NOTIFICATION_TYPE } from '../../notifications-table/useNotifications';

export default function useRecipientTemplateFile({ type, variables }) {
  const [headers, filename] = useMemo(() => {
    const headers =
      type === NOTIFICATION_TYPE.EMAIL ? ['email_address', 'name'] : ['phone_number'];

    const filename =
      type === NOTIFICATION_TYPE.EMAIL
        ? 'email_recipients.csv'
        : 'sms_recipients.csv';

    return [headers, filename];
  }, [type]);

  return useCallback(() => {
    const content = [...headers, ...variables.sort()].join(',');
    download(content, filename, 'text/csv');
  }, [filename, headers, variables]);
}
