import { useEffect } from 'react';
import { useActions, useValues } from 'kea';
import { templatesLogic } from '../../../../kea-logic';
import * as yup from 'yup';

export const useEmailTemplateForm = templateGroup => {
  const { updateTemplateGroup, getTemplateShells } = useActions(templatesLogic);
  const { updateErrors, updating } = useValues(templatesLogic);
  const { id, email_template } = templateGroup;

  const initialValues = {
    message: (email_template && email_template.message) || '',
    subject: (email_template && email_template.subject) || '',
    reply_to:
      (email_template && email_template.reply_to) || 'noreply@oforce.com',
    bcc:
      (email_template && email_template.bcc && email_template.bcc.join(', ')) ||
      ''
  };

  const schema = yup.object().shape({
    subject: yup.string().required('Required'),
    message: yup.string().required('Required'),
    reply_to: yup
      .string()
      .email()
      .required('Required'),
    bcc: yup.string()
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const { bcc, ...rest } = values;
    await updateTemplateGroup({
      id,
      email_template: { ...templateGroup, ...rest, bcc: normalizeBccs(bcc) }
    });
    setSubmitting(false);
  };

  useEffect(() => {
    getTemplateShells();
  }, [getTemplateShells]);

  const normalizeBccs = bcc =>
    bcc.split(',').reduce((acc, bcc) => [...acc, bcc.trim()], []);

  return {
    initialValues,
    schema,
    handleSubmit,
    updateErrors,
    updating
  };
};
