import * as yup from 'yup';
import { parseCsvFromBlob, parseCsvFromString } from './csv';

const ALL_RECIPIENTS = 'ALL';
const EMAIL_RECIPIENTS = 'EMAIL';
const SMS_RECIPIENTS = 'SMS';

const defaultOpts = { include: ALL_RECIPIENTS };

async function parseRecipientsFromBlob(blob, options = defaultOpts) {
  const lines = await parseCsvFromBlob(blob);
  return readRecipients(lines, options);
}

function parseRecipientsFromString(content, options = defaultOpts) {
  const lines = parseCsvFromString(content);
  return readRecipients(lines, options);
}

const wants = (recipientType) => (type) =>
  type === recipientType || type === ALL_RECIPIENTS;

const wantsEmail = wants(EMAIL_RECIPIENTS);
const wantsSms = wants(SMS_RECIPIENTS);

const readRecipients = (lines, { include: type }) => {
  const recipients = lines.reduce((acc, candidate) => {
    const email = readEmailRecipient(candidate);
    const sms = readSmsRecipient(candidate);
    if (sms && wantsSms(type)) acc.push(sms);
    if (email && wantsEmail(type)) acc.push(email);
    return acc;
  }, []);

  return [...recipients];
};

const readEmailRecipient = ({ email_address, name, ...data }) => {
  return email_address && new EmailRecipient(email_address, name, { ...data });
};

const readSmsRecipient = ({ phone_number, ...data }) => {
  return phone_number && new SmsRecipient(phone_number, { ...data });
};

const EMAIL_ADDRESS_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export class EmailRecipient {
  constructor(address, name, data = {}) {
    this.address = address.trim();
    this.name = name?.trim();
    this.data = { name, email_address: address, ...data };
  }

  toString() {
    if (this.name) return `${this.name} (${this.address})`;
    return this.address;
  }

  isValid() {
    return EMAIL_ADDRESS_REGEX.test(this.address);
  }

  serialize() {
    return Serializer.serialize(this);
  }

  static parse(json) {
    return Serializer.deserialize(json);
  }

  static schema() {
    return yup.object({
      name: yup.string().nullable(),
      address: yup.string().email(),
    });
  }
}

export class SmsRecipient {
  constructor(phoneNumber, data = {}) {
    this.phoneNumber = phoneNumber;
    this.data = { phone_number: phoneNumber, ...data };
  }

  toString() {
    return this.phoneNumber;
  }

  serialize() {
    return Serializer.serialize(this);
  }

  static parse(json) {
    return Serializer.deserialize(json);
  }

  static schema() {
    return yup.object({
      phoneNumber: yup.string().nullable(),
    });
  }
}

function guardSetMember(recipient) {
  if (
    !recipient instanceof EmailRecipient &&
    recipient instanceof SmsRecipient
  ) {
    throw Error('recipient must be either EmailRecipient or SmsRecipient');
  }
}

export class RecipientSet {
  constructor(map = new Map()) {
    this.map = map;
  }

  add(recipient) {
    guardSetMember(recipient);
    this.map.set(recipient.toString(), recipient);
  }

  [Symbol.iterator]() {
    return this.map.values();
  }

  get length() {
    return Array.from(this.map).length;
  }

  serialize() {
    return Serializer.serialize(this);
  }

  merge(set) {
    if (!(set instanceof RecipientSet))
      throw Error('set must be a RecipientSet');
    const newSet = new RecipientSet();
    [...this, ...set].forEach((x) => newSet.add(x));
    return newSet;
  }

  *emailRecipients() {
    for (const recipient of this) {
      if (recipient instanceof EmailRecipient) yield recipient;
    }
  }

  *smsRecipients() {
    for (const recipient of this) {
      if (recipient instanceof SmsRecipient) yield recipient;
    }
  }

  static parse(json) {
    if (!json) return new RecipientSet();
    const set = Serializer.deserialize(json);
    return set instanceof RecipientSet ? set : new RecipientSet();
  }

  delete(recipient) {
    guardSetMember(recipient);
    return this.map.delete(recipient.toString());
  }
}

const Serializer = {
  serialize(obj) {
    return JSON.stringify(obj, Serializer._replace);
  },

  deserialize(json) {
    return JSON.parse(json, Serializer._revive);
  },

  _replace(_key, value) {
    if (value instanceof EmailRecipient) {
      const { ...properties } = value;
      return {
        _type: 'EmailRecipient',
        value: { ...properties },
      };
    }
    if (value instanceof SmsRecipient) {
      const { ...properties } = value;
      return {
        _type: 'SmsRecipient',
        value: { ...properties },
      };
    }
    if (value instanceof Map) {
      return {
        _type: 'Map',
        value: Array.from(value.entries()),
      };
    }
    if (value instanceof RecipientSet) {
      return {
        _type: 'RecipientSet',
        value: value.map,
      };
    }
    return value;
  },

  _revive(_key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value._type === 'EmailRecipient') {
        const { address, name, data } = value.value;
        return new EmailRecipient(address, name, data);
      }
      if (value._type === 'SmsRecipient') {
        const { phoneNumber, data } = value.value;
        return new SmsRecipient(phoneNumber, data);
      }
      if (value._type === 'Map') {
        return new Map(value.value);
      }
      if (value._type === 'RecipientSet') {
        return new RecipientSet(value.value);
      }
      return value;
    }
    return value;
  },
};

export {
  parseRecipientsFromBlob,
  parseRecipientsFromString,
  ALL_RECIPIENTS,
  EMAIL_RECIPIENTS,
  SMS_RECIPIENTS,
};
